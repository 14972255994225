<template>
  <div id="map" style="height: 500px; width: 100%;"></div>
</template>


<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

export default {
  name: 'MapComponent',
  mounted() {
    const map = L.map('map').setView([47.413220, -1.219482], 13);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenStreetMap contributors',
    }).addTo(map);

    L.marker([47.413220, -1.219482]).addTo(map)
      .bindPopup('You are here')
      .openPopup();
  },
};
</script>


<style>
@import 'leaflet/dist/leaflet.css';
</style>
