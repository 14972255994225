<template>
  <div>
    <h1>Welcome to My App</h1>
    <MapComponent />
    <NotesComponent />
  </div>
</template>

<script>
import MapComponent from '../components/MapComponent.vue';
import NotesComponent from '../components/NotesComponent.vue';

export default {
  name: 'Home',
  components: {
    MapComponent,
    NotesComponent,
  },
};
</script>

<style scoped>
/* Styles specific to Home.vue */
</style>
