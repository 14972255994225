<template>
  <div>
    <h2>Notes</h2>
    <form @submit.prevent="addNote">
      <input v-model="newNote" placeholder="Add a note" required />
      <button type="submit">Add</button>
    </form>
    <ul>
      <li v-for="note in notes" :key="note.id">
        {{ note.content }}
        <button @click="deleteNote(note.id)">Delete</button>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'NotesComponent',
  data() {
    return {
      newNote: '',
      notes: [],
    };
  },
  methods: {
    async fetchNotes() {
      try {
        const response = await axios.get('/api/notes');
        this.notes = response.data;
      } catch (error) {
        console.error('Error fetching notes:', error);
      }
    },
    async addNote() {
      try {
        const response = await axios.post('/api/notes', { content: this.newNote });
        this.notes.push(response.data);
        this.newNote = '';
      } catch (error) {
        console.error('Error adding note:', error);
      }
    },
    async deleteNote(id) {
      try {
        await axios.delete(`/api/notes/${id}`);
        this.notes = this.notes.filter(note => note.id !== id);
      } catch (error) {
        console.error('Error deleting note:', error);
      }
    },
  },
  created() {
    this.fetchNotes();
  },
};
</script>

<style scoped>
/* Styles for NotesComponent.vue */
</style>
